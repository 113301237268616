@import url('https://fonts.googleapis.com/css2?family=Lato&family=Nunito+Sans&family=Montserrat&display=swap');

html, body {
  height:100%;
}

body {
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.logo {
  max-width: 500px;
  width: 100%;
  display: block;
  margin: 2em auto;
}

#error-logo {
  margin-top: 0;
}

a {
  color: #0082CA;
  text-decoration: underline;
}

h1 {
  color: #0082CA;
  font-family: 'Lato',sans-serif;
  font-style: normal;
  font-size: 30px;
  font-weight: 400;
  margin: 60px 0;
  text-align: center;
}

h2 {
  color: #9e9e9e;
  font-family: 'Nunito Sans',sans-serif;
  font-style: normal;
  font-size: 36px;
  font-weight: 400;
  margin: 60px 0 50px;
  text-align: center;
}

h3 {
  font-family: 'Lato',sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  font-style: normal;
}

h3.title {
  color: #9e9e9e;
  font-family: 'Nunito Sans',sans-serif;
  font-style: normal;
  font-size: 28px;
  font-weight: 400;
  margin: 10px 0 50px;
  text-align: center;
}

.icon-enter {
  opacity: 0;
}

.icon-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.icon-exit {
  opacity: 1;
}

.icon-exit-active {
  opacity: 0;
  transition: opacity 0ms ease-out;
}

.country-region-box {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  p {
    margin: 0;
    color: #cb4641;
    display: none;
    font-size: 13.7px;
    margin-top: 3px;
    margin-left: 14px;
  }
}

#country, #region, #phone-number {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18.28px;
  width: 91.5%;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  color: #666666;
  letter-spacing: inherit;
  box-sizing: content-box;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  padding: 16.5px 7px 16.5px 22px;
  outline: none;
  font-family: 'Lato';

  @media (max-width: 600px) {
    width: 93%;
  }

  &.error {
    border: 1px solid #cb4641;
  }
}

.country-region-box {
  width: 100%;

  div {
    width: 48%;

    @media (max-width: 600px) {
      width: 100%;

      &:first-child {
        margin-bottom: 1.2em;
      }
    }
  }
}

#phone-required,
#cpa-required {
  margin: 0;
  color: #cb4641;
  display: none;
  font-size: 13.7px;
  margin-top: 3px;
  margin-left: 14px;
}

/* IE11 hide native button */
select::-ms-expand {
display: none;
}

select.minimal {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='30' viewBox='0 0 24 24' width='30' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z' fill='gray'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

// phone
.PhoneInput {
  position: relative;
}

.PhoneInputCountry {
  position: absolute !important;
  top: 20px;
  left: 20px;
}

#phone-number {
  padding-left: 68px;
}

// step 4
#step-4-page > .MuiTextField-root {
  margin-bottom: 0 !important;
}

#password-section {
  margin-top: 1.5em;
}

// spinner
.MuiCircularProgress-root {
  height: 28px !important;
  width: 28px !important;

   > svg {
    height: 28px !important;
    width: 28px !important;
   }
}

.navigate {
  .MuiBox-root {
    .MuiButton-root {
      width: 180px;
    }
  }
}